import { useSelector } from 'react-redux'

/**
 * Find pages within redux
 * @example
 * // return a single page object
 * usePages.findOne({ id: 1 })
 * @example
 * // returns an array of page objects
 * usePages.findAll({ section: 'Pages' })
 */
const usePages = () => {
  // Get all pages from global state
  const { pages, singles } = useSelector((state) => state.api)

  const find = (config) => {
    return [...pages, ...singles].filter((page) => {
      return Object.entries(config)
        .map(([key, value]) => page[key] === value)
        .every((value) => value === true)
    })
  }

  /**
   * Find an entry that has values specified in the config object
   */
  const findOne = (config = {}) => {
    return find(config)[0]
  }

  /**
   * Find all entries that have values specified in the config object
   */
  const findAll = (config = {}) => {
    return find(config)
  }

  return {
    findOne,
    findAll,
    pages,
    singles,
  }
}

export default usePages
