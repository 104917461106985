import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import CustomNavLink from '../CustomNavLink'
import useMediaQuery from 'hooks/useMediaQuery'
import { ReactComponent as LogoText } from 'images/logos/text.svg'
import { ReactComponent as LogoSolo } from 'images/logo.svg'
import configExport from 'scss/bootstrap/config.scss'
import Icon from 'components/Icon'
import { Sling } from 'hamburger-react'
import { useState, useEffect } from 'react'
import styleConfig from 'scss/bootstrap/config.scss'
import { motion } from 'framer-motion'
import AccountModal from './AccountModal'

const NavigationLinks = ({ nodes }) => {
  return (
    <Nav className={`navigation-bar-links`}>
      {nodes.map((node, index) => {
        return <CustomNavLink key={index} node={node} />
      })}
    </Nav>
  )
}

const DesktopNav = ({ nodes }) => {
  const location = useLocation()
  return (
    <>
      <NavigationLinks nodes={nodes.left} />
      <Navbar.Brand
        as={Link}
        to="/"
        title="Home"
        style={{
          height: 150,
        }}
      >
        {location.pathname === '/' ? <LogoText /> : <LogoSolo />}
      </Navbar.Brand>
      <NavigationLinks nodes={nodes.right} />
    </>
  )
}

const variants = {
  closed: {
    opacity: 0,
    x: 100,
    pointerEvents: 'none',
  },
  open: {
    transition: {
      staggerChildren: 0.07,
      opacity: 1,
      x: 0,
    },
    zIndex: 999,
  },
}

const MobileNav = ({ nodes, isOpen, setOpen }) => {
  return (
    <>
      <Navbar.Brand
        as={Link}
        to="/"
        title="Home"
        style={{
          height: 75,
        }}
      >
        <LogoSolo />
      </Navbar.Brand>
      <div className="mobile-nav-controls">
        <Link to="search" title="Search">
          <Icon name="search" />
        </Link>
        <Sling rounded size={35} color={styleConfig.green} toggled={isOpen} easing="ease-in-out" duration={0.3} toggle={setOpen} />
      </div>
      <motion.div animate={isOpen ? 'open' : 'closed'} variants={variants} style={{ zIndex: 1 }} className="mobile-nav navbar-nav">
        {[...nodes.left, ...nodes.right].map((node, index) => (
          <motion.div
            key={node + '-' + index}
            variants={{
              closed: { x: -20, opacity: 0 },
              open: { x: 0, opacity: 1 },
            }}
          >
            <CustomNavLink node={node} key={node.id} />
          </motion.div>
        ))}
      </motion.div>
    </>
  )
}

const NavigationBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const isDesktop = useMediaQuery(`(min-width: ${configExport['breakpoint-xl']}`)

  const { navigationNodes: nodes } = useSelector((state) => state.api)

  useEffect(() => {
    mobileOpen && isDesktop && setMobileOpen(false)
  }, [isDesktop])

  return (
    <navigation-bar>
      <Navbar className={`${isDesktop ? 'desktop' : 'mobile'}`}>
        {isDesktop ? <DesktopNav nodes={nodes} /> : <MobileNav setOpen={setMobileOpen} isOpen={mobileOpen} nodes={nodes}></MobileNav>}
        <AccountModal />
      </Navbar>
    </navigation-bar>
  )
}

export default NavigationBar
