import { AnimatePresence } from 'framer-motion'
import usePages from 'hooks/usePages'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import BaseLayout from './layouts/BaseLayout'
import { NotFoundPage, DynamicPage, HomePage, SearchPage, SpeciesPage, ContactPage, AwardPage, GalleryPage, BreedingRecordPage, NewsEventsPage, JoinPage } from './pages'
import useAuth from 'hooks/useAuth'
import { useDispatch } from 'react-redux'
import { setUser } from './redux/stateSlice'
import useFetch from 'hooks/useFetch'
import { Toaster } from 'react-hot-toast'

const AppRoutes = () => {
  const location = useLocation()
  const { pages, findOne: findPage } = usePages()
  const fetch = useFetch()
  const auth = useAuth()
  const dispatch = useDispatch()

  const homeProps = findPage({ uri: '__home__' })
  const contactProps = findPage({ slug: 'contact' })
  const awardsProps = findPage({ slug: 'awards' })
  const galleryProps = findPage({ slug: 'gallery' })
  const newsEventsProps = findPage({ slug: 'news-events' })
  const breedingRecordsProps = findPage({ slug: 'breeding-records' })
  const speciesIndexProps = findPage({ slug: 'species-index' })
  const magazinesProps = findPage({ slug: 'magazines' })
  const joinProps = findPage({ slug: 'join' })

  const searchPages = [
    { ...speciesIndexProps, uri: '/species-index', index: 'species', title: 'Species Index', resetSearch: true },
    { ...breedingRecordsProps, uri: '/breeding-records', index: 'breedingRecords', title: 'First UK Breeding Records', resetSearch: true },
    { ...magazinesProps, uri: '/magazines', title: 'Magazine Archive', index: 'magazines', resetSearch: true },
    { uri: '/search', title: 'Search' },
  ]

  useEffect(() => {
    fetch.cancelAll()

    auth.check((res) => dispatch(setUser(res)))

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', // Optional if you want to skip the scrolling animation
    })
  }, [location.pathname])

  return (
    <AnimatePresence mode="wait">
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            fontFamily: 'Intro',
          },
          success: {
            style: {
              border: '1px solid #1abf67',
              color: '#1abf67',
            },
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
        }}
      />

      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<BaseLayout />}>
          {/* Singles */}
          <Route index element={<HomePage {...homeProps} />} />
          <Route path="/contact" element={<ContactPage {...contactProps} />} />

          <Route path="/awards" element={<AwardPage title="Awards" {...awardsProps} />} />

          <Route path="/gallery" element={<GalleryPage title="Gallery" {...galleryProps} />} />

          <Route path="species/:slug" element={<SpeciesPage />} />

          <Route path="breeding-records/:slug" element={<BreedingRecordPage />} />
          {/* Section Entry routes */}
          <Route path=":section/:slug" element={<DynamicPage />} />

          <Route path="/join" element={<JoinPage {...joinProps} />} />

          <Route path="/news-events" element={<NewsEventsPage {...newsEventsProps} uri={'/news-events'} title={'News & Events'} index={'news'} />} />

          {searchPages.map((page, index) => {
            return <Route key={index} path={page.uri} element={<SearchPage {...page} />} />
          })}
          {/* Page Entry routes */}
          {pages.map((page, index) => {
            return <Route key={index} path={page.uri} element={<DynamicPage {...page} />} />
          })}

          {/* 404 Fallback */}
          <Route path="*" element={<NotFoundPage />} status={404} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default AppRoutes
