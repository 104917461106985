// Redux
import { useDispatch } from 'react-redux'
import { setError } from 'redux/stateSlice'

function useFetch() {
  const dispatch = useDispatch()

  const controller = new AbortController()
  const signal = controller.signal

  const requestTimeout = 5000

  const cancelAll = () => {
    controller.abort()
  }

  const getRequest = async (url) => {
    const config = {
      signal: signal,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: requestTimeout,
    }

    const res = await fetch(url, config)

    if (!res.ok && !res.success) {
      _throwError(res)
    }

    return res.json()
  }

  const postRequest = async (url, params) => {
    const config = {
      signal: signal,
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params),
      timeout: requestTimeout,
    }

    const res = await fetch(url, config)

    // if (!res.ok || !res.success) {
    //   _throwError(res)
    // }

    return res.json()
  }

  const putRequest = async (url, params) => {
    const config = {
      signal: signal,
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params),
      timeout: requestTimeout,
    }

    const res = await fetch(url, config)

    if (!res.ok) {
      _throwError(res)
    }

    return res.json()
  }

  const fileUploadRequest = async (url, params) => {
    let uploadKey = 'uploadKey-' + new Date().getTime()
    let formData = new FormData()
    formData.append('assets-upload', params.asset)
    formData.append('folderId', params.folderId)
    formData.append('uploadKey', uploadKey)

    const config = {
      signal: signal,
      mode: 'cors',
      method: 'post',
      credentials: 'include',
      body: formData,
      timeout: requestTimeout,
    }

    const res = await fetch(url, config)

    if (!res.ok) {
      _throwError(res)
    }

    return res.json()
  }

  const getAllRequest = async (urls) => {
    const requests = urls.map(async (url) => await getRequest(url))

    return await Promise.all(requests)
  }

  const _throwError = (res) => {
    const err = {
      heading: `${res.status}: ${res.statusText}`,
      body: res.url,
    }

    dispatch(setError(err))

    throw new Error(`An error has occured: ${err.heading}`)
  }

  return { cancelAll, getRequest, getAllRequest, postRequest, putRequest, fileUploadRequest }
}

export default useFetch
