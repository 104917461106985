import { Nav } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Icon from './Icon'

const CustomNavLink = ({ node }) => {
  const location = useLocation()

  const linkData = {
    target: undefined,
  }

  if (node.newWindow === '1') {
    linkData.target = '_blank'
    linkData.rel = 'noreferrer'
  }

  const isExternal = node.type !== 'Entry' && node.url.includes('https://')

  switch (node.type) {
    case 'Custom URL':
      if (node.url.includes('https://')) {
        const entryUri = new URL(node.url).pathname
        linkData.as = Link
        linkData.to = entryUri
      } else {
        const entryUri = new URL('https://www.avisoc.loc' + node.url).pathname
        linkData.as = Link
        linkData.to = entryUri
      }
      break
    case 'Entry':
      const entryUri = new URL(node.url).pathname
      linkData.as = Link
      linkData.to = entryUri
      break
    case 'manual':
      if (isExternal) {
        linkData.href = node.url
      } else {
        linkData.as = Link
        linkData.to = node.url
      }
      break
    default:
      break
  }

  return (
    <Nav.Item className={node.children.length > 0 ? 'has-children' : ''}>
      <Nav.Link {...linkData} active={location.pathname === linkData.to} className={location.pathname === linkData.to ? 'underline' : ''}>
        <span className="link-title">
          {node.title === 'Search' ? <Icon name="search" /> : <></>}
          {node.title}
          {isExternal ? (
            <span className="link-external">
              <Icon name="external" />
            </span>
          ) : (
            ''
          )}
        </span>
      </Nav.Link>

      {node.children.length > 0 ? (
        <div className="dropdown">
          {node.children.map((child, key) => {
            return <CustomNavLink key={'dd-' + node.id + '-' + key} node={child} />
          })}
        </div>
      ) : (
        <></>
      )}
    </Nav.Item>
  )
}

export default CustomNavLink
