// Core
import { useState, useContext, createContext } from 'react'
import useFetch from 'hooks/useFetch'

const authUrls = {
  auth: `${process.env.REACT_APP_CMS_BASEURL}/auth`,
  login: `${process.env.REACT_APP_CMS_BASEURL}/login`,
  logout: `${process.env.REACT_APP_CMS_BASEURL}/logout`,
  forgottenPwd: `${process.env.REACT_APP_CMS_BASEURL}/send-password-reset-email`,
  updateGroup: `${process.env.REACT_APP_CMS_BASEURL}/subscribed`,
  update: `${process.env.REACT_APP_CMS_BASEURL}/update`,
}

let AuthContext = createContext(null)

export default function useAuth() {
  return useContext(AuthContext)
}

// Provider -
export function AuthProvider({ children }) {
  let req = useFetch()
  let [user, setUser] = useState(null)

  let check = async (callback) => {
    const res = await req.getRequest(authUrls.auth)
    if (res.success && res.data.id != null) {
      setUser(res.data)
      callback(res.data)
    } else {
      setUser(null)
      callback(false)
    }
  }

  let update = async (params, callback) => {
    const res = await req.postRequest(authUrls.update, params)
    if (res.success) {
      callback(res.data)
    } else {
      alert(res.message)
    }
  }

  let signin = async (credentials, callback) => {
    const res = await req.postRequest(authUrls.login, credentials)

    if (res.success) {
      setUser(res.data)
      callback(res.data)
    } else {
      // TODO: Handle errors
      alert(res.message)
    }
  }

  let setGroup = async (group) => {
    const res = await req.postRequest(authUrls.updateGroup, group)
    if (res.success) {
      setUser({ ...user, userGroups: [group] })
    }
  }

  let signout = async (callback) => {
    await req.getRequest(authUrls.logout)
    setUser(null)
    callback()
  }

  let forgottenPassword = async (credentials, callback) => {
    const res = await req.postRequest(authUrls.forgottenPwd, credentials)

    if (res.success) {
    } else {
      setUser(null)
      callback(false)
    }
  }

  let can = (permission) => (user ? user.permissions[permission] : false)

  let permissions = () => user.permissions || {}

  let value = { user, check, setGroup, signin, signout, can, permissions, forgottenPassword, update }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
