import { GrFacebookOption, GrInstagram, GrTwitter } from 'react-icons/gr'
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowBack, IoIosAlert, IoIosClock, IoIosCalendar, IoIosClose } from 'react-icons/io'
import { ReactComponent as logoSolo } from '../images/logo-solo.svg'
import { ReactComponent as logoWhite } from '../images/logo-white.svg'
import { ReactComponent as backgroundLines } from '../images/background-lines.svg'
import { ReactComponent as toucan } from '../symbols/toucan.svg'
import { ReactComponent as baliMyna } from '../symbols/bali-myna.svg'
import { ReactComponent as hummingBird1 } from '../symbols/humming-bird-1.svg'
import { ReactComponent as hummingBird2 } from '../symbols/humming-bird-2.svg'
import { ReactComponent as egg } from '../symbols/egg.svg'
import { ReactComponent as evidence } from '../symbols/evidence.svg'
import { ReactComponent as flamingo } from '../symbols/flamingo.svg'
import { ReactComponent as heart } from '../symbols/heart.svg'
import { ReactComponent as mapPin } from '../symbols/map-pin.svg'
import { ReactComponent as logo } from '../images/logo.svg'
import { ReactComponent as Check } from '../symbols/check.svg'
import { ReactComponent as Cross } from '../symbols/cross.svg'
import { ReactComponent as Download } from '../symbols/download.svg'
import { ReactComponent as Breeder } from '../symbols/breeder-icon.svg'
import { ReactComponent as Family } from '../symbols/family-icon.svg'
import { ReactComponent as Population } from '../symbols/population-icon.svg'
import { BiSearchAlt as Search } from 'react-icons/bi'

const Icon = ({ name }) => {
  const pickIcon = (name) => {
    switch (name) {
      case 'logoSolo':
        return logoSolo
      case 'logoWhite':
        return logoWhite
      case 'logo':
        return logo
      case 'download':
        return Download
      case 'backgroundLines':
        return backgroundLines
      case 'baliMyna':
        return baliMyna
      case 'hummingBird1':
        return hummingBird1
      case 'hummingBird2':
        return hummingBird2
      case 'flamingo':
        return flamingo
      case 'egg':
        return egg
      case 'evidence':
        return evidence
      case 'toucan':
        return toucan
      case 'heart':
        return heart
      case 'mapPin':
        return mapPin
      case 'check':
        return Check
      case 'cross':
        return Cross
      case 'breeder':
        return Breeder
      case 'family':
        return Family
      case 'population':
        return Population
      case 'clock':
        return IoIosClock
      case 'facebook':
        return GrFacebookOption
      case 'instagram':
        return GrInstagram
      case 'twitter':
        return GrTwitter
      case 'arrow-down':
        return IoIosArrowDown
      case 'arrow-right':
        return IoIosArrowForward
      case 'arrow-left':
        return IoIosArrowBack
      case 'external':
        return IoIosAlert
      case 'calendar':
        return IoIosCalendar
      case 'search':
        return Search
      case 'close':
        return IoIosClose
      default:
        throw new Error(`SVG with name ${name} was not found.`)
    }
  }

  const SVG = pickIcon(name)

  return <SVG className={name} />
}
export default Icon
