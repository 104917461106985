import { motion, AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import Icon from '../components/Icon'
import { ProgressBar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import '../scss/pages/LoadingScreen.scss'
import { setComponentData } from 'redux/apiSlice'

const LoadingScreen = ({ withLoad }) => {
  const { loadProgress } = useSelector((state) => state.state)
  const { components } = useSelector((state) => state.api)

  const variants = {
    beforeEnter: {
      opacity: 0,
      filter: 'blur(5px)',
      transform: 'scale(1)',
    },
    enter: {
      opacity: 1,
      filter: 'blur(0px)',
      transition: {
        duration: 0.5,
      },
      transform: 'scale(1)',
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
        timing: 'ease-in',
      },
    },
  }

  useEffect(() => {
    document.body.className = ''
    document.body.classList.add('loading-page')
  }, [loadProgress])

  const dispatch = useDispatch()

  if (!components['mobileHomeImg']) {
    const imageElement = new Image()
    imageElement.src = `${process.env.REACT_APP_CMS_BASEURL}/assets/uploads/images/avi-background-mobile.png`
    dispatch(setComponentData({ index: 'mobileHomeImg', data: imageElement.src }))
  }

  return (
    <div className="loading-screen">
      <motion.div className="loading-screen-container" variants={variants} initial="beforeEnter" animate="enter" exit="exit">
        <Icon name="logoSolo" />
        <AnimatePresence mode="wait">
          <ProgressBar now={loadProgress} animated />
        </AnimatePresence>
      </motion.div>
    </div>
  )
}

export default LoadingScreen
