import { configureStore } from '@reduxjs/toolkit'
import apiReducer from './apiSlice'
import stateReducer from './stateSlice'

export const store = configureStore({
  reducer: {
    state: stateReducer,
    api: apiReducer
  }
})
