import React from 'react'
import { useSelector } from 'react-redux'

import Icon from './Icon'

// import '../scss/components/social-icons.scss'

const SocialIcons = () => {
  const { socialLinks: socials } = useSelector((state) => state.api)

  const socialsArr = () => {
    const arr = []
    for (let key in socials) {
      arr.push(socials[key])
    }
    return arr
  }

  return (
    <div className="social-icons">
      {socialsArr().map((social, index) => {
        if (social.url) {
          return (
            <a key={index} href={social.url} target="_blank" rel="noreferrer" className={`social-icon ${social.handle}`}>
              <Icon name={social.handle} />
            </a>
          )
        } else {
          return ''
        }
      })}
    </div>
  )
}

export default SocialIcons
