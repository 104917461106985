import { lazy } from 'react'

export const NotFoundPage = lazy(() => import('./404'))
export const DynamicPage = lazy(() => import('./DynamicPage'))
export const HomePage = lazy(() => import('./HomePage'))
export const SearchPage = lazy(() => import('./SearchPage'))
export const SpeciesPage = lazy(() => import('./SpeciesPage'))
export const BreedingRecordPage = lazy(() => import('./BreedingRecordPage'))
export const ContactPage = lazy(() => import('./ContactPage'))
export const AwardPage = lazy(() => import('./AwardPage'))
export const GalleryPage = lazy(() => import('./GalleryPage'))
export const NewsEventsPage = lazy(() => import('./NewsEventsPage'))
export const JoinPage = lazy(() => import('./JoinPage'))
