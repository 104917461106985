import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pages: [],
  singles: [],
  globals: {},
  navigationNodes: [],
  components: {},
}

const apiSlice = createSlice({
  name: 'globals',
  initialState: initialState,
  reducers: {
    setNavigationData: (state, action) => {
      state.navigationNodes = action.payload
    },
    setGlobalsData: (state, action) => {
      const json = action.payload
      json.forEach((globalSet) => {
        state.globals[globalSet.handle] = globalSet
      })
    },
    setSingleData: (state, action) => {
      state.singles = action.payload
    },
    setPageData: (state, action) => {
      state.pages = action.payload
    },
    setComponentData: (state, action) => {
      if (action.payload.index !== undefined) {
        state.components[action.payload.index] = action.payload.data
      }
    },
  },
})

// Export actions to dispatch within components
export const { setNavigationData, setGlobalsData, setSingleData, setPageData, setComponentData } = apiSlice.actions

// Export reducer for store
export default apiSlice.reducer
