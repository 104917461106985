import { motion } from 'framer-motion'
import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import '../scss/components/image.scss'

const Image = (props) => {
  let { image, className, name, blowout, onClick, alt } = props

  const { globals } = useSelector((state) => state.api)
  const placeholder = globals.siteGlobals.customFields.placeholderImage

  const [imgSrc, setImageSrc] = useState('')

  const pickImage = (name) => {
    switch (name) {
      case 'bird-1':
        return '/assets/images/bird-1.png'
      case 'bird-2':
        return '/assets/images/bird-2.png'
      case 'bird-3':
        return '/assets/images/bird-3.png'
      case 'bird-4':
        return '/assets/images/bird-4.png'
      case 'bird-5':
        return '/assets/images/bird-5.png'
      case 'bird-6':
        return '/assets/images/bird-6.png'
      case 'bird-7':
        return '/assets/images/bird-7.png'
      case 'barbet':
        return '/assets/images/barbet.png'
      case 'ezraPuffin':
        return '/assets/images/ezra-puffin.png'
      case 'flamingoPuffin':
        return '/assets/images/flamingo-puffin.png'
      case 'flamingo':
        return '/assets/images/flamingo.png'
      case 'flyingParrot':
        return '/assets/images/flying-parrot.png'
      case 'smallBird':
        return '/assets/images/small-bird.png'
      case 'birds':
        return '/assets/images/birds.png'
      case 'touchBirds':
        return '/assets/images/touch-birds.png'
      case 'galleryBirds':
        return '/assets/images/gallery-birds.png'
      case 'hornbillParadise':
        return '/assets/images/hornbill-paradise.png'
      case 'hornbillParrot':
        return '/assets/images/hornbill-parrot.png'
      case 'hornbill':
        return '/assets/images/hornbill.png'
      case 'kingfishers':
        return '/assets/images/kingfishers.png'
      case 'birds2':
        return '/assets/images/birds-2.png'
      case 'parrot':
        return '/assets/images/parrot.png'
      case 'parrot2':
        return '/assets/images/parrot-2.png'
      case 'toucan':
        return '/assets/images/toucan.png'
      case 'presidentFlamingo':
        return '/assets/images/president-flamingo.png'
      case 'risdonKingfisher':
        return '/assets/images/risdon-kingfisher.png'
      case 'birds3':
        return '/assets/images/birds-3.png'
      case 'starlingGreenBird':
        return '/assets/images/starling-greenbird.png'
      case 'starling':
        return '/assets/images/starling.png'
      case 'starling2':
        return '/assets/images/starling-2.png'
      case 'funding-options':
        return '/assets/images/funding-options.png'
      case 'arrow-1':
        return '/assets/images/arrow-1.png'
      case 'arrow-2':
        return '/assets/images/arrow-2.png'
      case 'arrow-3':
        return '/assets/images/arrow-3.png'
      case 'arrow-4':
        return '/assets/images/arrow-4.png'
      case 'become-a-member':
        return '/assets/images/become-a-member.png'
      case 'events-image':
        return '/assets/images/events-image.png'
      default:
        throw new Error(`Image with name ${name} was not found.`)
    }
  }

  const getBlowoutDimensions = (name, size) => {
    let position = []
    switch (name) {
      case 'barbet':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'ezraPuffin':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'flamingoPuffin':
        position = { x1: 390, x2: 0, y1: 420, y2: 430, imageSize: size, blowoutSize: 140 }
        return position
      case 'flamingo':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'flyingParrot':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 50 }
        return position
      case 'smallBird':
        position = { x1: 160, x2: 0, y1: -70, y2: 80, imageSize: size, blowoutSize: 8 }
        return position
      case 'birds':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'touchBirds':
        position = { x1: 655, x2: 0, y1: 610, y2: 420, imageSize: size, blowoutSize: 140 }
        return position
      case 'galleryBirds':
        position = { x1: 0, x2: 0, y1: 420, y2: 420, imageSize: size, blowoutSize: 140 }
        return position
      case 'hornbillParadise':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'hornbillParrot':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'hornbill':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'kingfishers':
        position = { x1: 560, x2: 0, y1: 640, y2: 530, imageSize: size, blowoutSize: 50 }
        return position
      case 'birds2':
        position = { x1: 520, x2: 0, y1: 610, y2: 410, imageSize: size, blowoutSize: 0 }
        return position
      case 'parrot':
        position = { x1: 151, x2: 0, y1: 151, y2: 75, imageSize: size, blowoutSize: 5 }
        return position
      case 'parrot2':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'toucan':
        position = { x1: 550, x2: 0, y1: 550, y2: 360, imageSize: size, blowoutSize: 50 }
        return position
      case 'presidentFlamingo':
        position = { x1: 520, x2: 0, y1: 380, y2: 410, imageSize: size, blowoutSize: 140 }
        return position
      case 'risdonKingfisher':
        position = { x1: 520, x2: 0, y1: 500, y2: 360, imageSize: size, blowoutSize: 85 }
        return position
      case 'birds3':
        position = { x1: 650, x2: 0, y1: 620, y2: 390, imageSize: size, blowoutSize: 140 }
        return position
      case 'starlingGreenBird':
        position = { x1: 520, x2: 0, y1: 610, y2: 460, imageSize: size, blowoutSize: 140 }
        return position
      case 'starling':
        position = { x1: 100, x2: 0, y1: 100, y2: 100, imageSize: size, blowoutSize: 5 }
        return position
      case 'starling2':
        position = { x1: 520, x2: 0, y1: 610, y2: 100, imageSize: size, blowoutSize: 5 }
        return position
      default:
        return
    }
  }

  const imageLoaded = () => {
    if (name) {
      // Loads a static image
      const image = `${process.env.REACT_APP_CMS_BASEURL}${pickImage(name)}`
      setImageSrc(
        image !== undefined && image !== null && image.url && image.url !== '' ? image.url : image !== undefined && image !== null && image !== '' ? image : placeholder.url,
      )
    } else {
      setImageSrc(
        image !== undefined && image !== null && image.url && image.url !== '' ? image.url : image !== undefined && image !== null && image !== '' ? image : placeholder.url,
      )
    }
  }

  let path = ''

  if (blowout && !blowout.position) {
    blowout.position = getBlowoutDimensions(blowout.id, blowout.imageSize)
  }

  if (blowout && blowout.position) {
    path = `path('M ${blowout.position.x0 ?? 0} 0 L ${blowout.position.x1 ?? blowout.imageSize + blowout.blowoutSize} ${blowout.position.x2 ?? 0} L ${
      blowout.position.y1 ?? blowout.imageSize + blowout.blowoutSize
    } ${blowout.position.y2 ?? blowout.imageSize / 2} L ${blowout.position.y0 ?? 0} ${blowout.position.y2 ?? blowout.imageSize / 2} Z')`
  } else if (blowout) {
    path = `path('M 0 0 L ${blowout.imageSize + blowout.blowoutSize} ${0} L ${blowout.imageSize + blowout.blowoutSize} ${blowout.imageSize / 2} L 0 ${blowout.imageSize / 2} Z')`
  }

  return (
    <>
      {blowout ? (
        <motion.svg
          className={'blowout ' + (className ?? '')}
          viewport={{ once: true, margin: '175px' }}
          whileInView={imageLoaded}
          onClick={onClick}
          style={{
            '--imageSize': blowout.position ? blowout.position.imageSize : blowout.imageSize,
            '--imageBlowout': blowout.position ? blowout.position.blowoutSize : blowout.blowoutSize,
          }}
        >
          <defs>
            <clipPath id={`image-mask-${blowout.id}`}>
              <circle r={blowout.imageSize / 2 + 1} cx={blowout.imageSize / 2} cy={blowout.imageSize / 2} />
              <path
                className="path"
                style={{
                  d: `${path}`,
                }}
              />
            </clipPath>
          </defs>
          <image
            clipPath={`url(#image-mask-${blowout.id}`}
            href={imgSrc}
            x={`${blowout.position && blowout.position.x ? blowout.position.x : 0}`}
            y={`${blowout.position && blowout.position.y ? blowout.position.y : 0}`}
          ></image>
        </motion.svg>
      ) : (
        <motion.img
          className={className}
          src={imgSrc}
          viewport={{ once: true, margin: '175px' }}
          whileInView={imageLoaded}
          onClick={onClick}
          data-src={imgSrc ? imgSrc : image !== undefined && image !== null && image.url ? image.url : image !== undefined && image !== null ? image : placeholder.url}
          alt={
            alt !== undefined && alt !== null
              ? alt
              : image !== undefined && image !== null
              ? image.title
              : name !== undefined && name !== null
              ? name
              : placeholder.title
              ? placeholder.title
              : 'Placeholder Image'
          }
        ></motion.img>
      )}
    </>
  )
}

export default Image
