import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import Footer from '../components/global/Footer'
import NavigationBar from '../components/global/NavigationBar'

const BaseLayout = () => {
  return (
    <>
      <Helmet>
        {/* <meta charSet="utf-8" /> */}

        {/* TODO: favicon */}
        {/* <link rel="icon" href={faviconPath} sizes="any" type="image/svg+xml"></link> */}

        <meta name="author" content="The Avicultural Society" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="preconnect" href="https://use.typekit.net" crossorigin />
        <link rel="stylesheet" href="https://use.typekit.net/grm0tha.css" />
      </Helmet>

      {/* Global Navigation Bar */}
      <div className="site-alert">Site under development</div>

      <NavigationBar></NavigationBar>

      {/* Backgrounds */}
      {/* <BackgroundLogo /> */}
      {/* <background-gradient /> */}

      {/* Main Content */}
      <Outlet />

      {/* Footer */}
      <Footer />
    </>
  )
}

export default BaseLayout
