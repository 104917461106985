import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loaded: false,
  searchParams: [],
  results: [],
  filters: [],
  filtersByIndex: [],
  searchInit: false,
  loadProgress: 0,
  currentUser: null,
  page: 1,
  previousQuery: [],
  isSearching: false,
}

const stateSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    searchInitialised: (state, action) => {
      state.searchInit = action.payload
    },
    loadingComplete: (state) => {
      state.loaded = true
    },
    setSearch: (state, action) => {
      state.searchParams = action.payload
    },
    setFiltersForIndex: (state, action) => {
      state.filters = action.payload
    },
    setFiltersValuesByIndex: (state, action) => {
      let payload = action.payload
      let index = payload.index
      let data = payload.data
      state.filtersByIndex.push({ index: index, data: data })
    },
    setResults: (state, action) => {
      state.results = action.payload
    },
    siteLoadProgress: (state, action) => {
      state.loadProgress = state.loadProgress + action.payload
    },
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
    setCurrentPage: (state, action) => {
      state.page = parseInt(action.payload)
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPreviousQuery: (state, action) => {
      state.previousQuery = action.payload
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload
    },
  },
})

// Export actions to dispatch within components
export const {
  loadingComplete,
  setSearch,
  setFiltersForIndex,
  setFiltersValuesByIndex,
  setResults,
  searchInitialised,
  siteLoadProgress,
  setUser,
  setCurrentPage,
  setError,
  setPreviousQuery,
  setIsSearching,
} = stateSlice.actions

// Export reducer for store
export default stateSlice.reducer
