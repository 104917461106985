import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import LoadingScreen from './pages/LoadingScreen'
import { AnimatePresence } from 'framer-motion'

import { setGlobalsData, setNavigationData, setPageData, setSingleData } from './redux/apiSlice'
import { loadingComplete, siteLoadProgress } from './redux/stateSlice'
import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthProvider } from 'hooks/useAuth'

const App = () => {
  const dispatch = useDispatch()
  const { loaded, loadProgress } = useSelector((state) => state.state)

  const loadConfig = [
    {
      name: 'navigation',
      endpoint: 'api/navigation',
      action: setNavigationData,
    },
    {
      name: 'pages',
      endpoint: 'api/entries/pages',
      action: setPageData,
    },
    {
      name: 'singles',
      endpoint: 'api/singles',
      action: setSingleData,
    },
    {
      name: 'globals',
      endpoint: 'api/globals',
      action: setGlobalsData,
    },
  ]

  useEffect(() => {
    const loadData = async () => {
      const requests = loadConfig.map(async (config) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_CMS_BASEURL}/${config.endpoint}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
            },
          })

          let json = await response.json()
          if (json.data !== undefined) json = json.data
          dispatch(config.action(json))
          if (loadProgress < 100) {
            dispatch(siteLoadProgress(25))
          }
          return true
        } catch (error) {
          console.log('Errored at ' + config.name, error)
        }
      })

      Promise.all(requests)
        .then(() => dispatch(loadingComplete()))
        .catch((error) => console.log(error))
    }

    loadData()
  }, [])

  return (
    <AuthProvider>
      <BrowserRouter>
        <AnimatePresence mode="wait">{!loaded ? <LoadingScreen key="loading" /> : <AppRoutes key="routes" />}</AnimatePresence>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
